import React, { useState } from "react";
import {
  getModuleAccess,
  getModules,
  Module as ModuleType,
} from "../../api/ModulesApi";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import SkillsImg from "../../img/headers/Skills.svg";
import ModulesImg from "../../img/headers/Modules.svg";
import { getLessons, Lesson } from "../../api/LessonsApi";
import { getModuleCount } from "../../api/UserApi";
import { ModuleView } from "./ModuleView";

export function ModulesList({ skills }: { skills: boolean }) {
  const [modules, setModules] = useState<ModuleType[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [moduleAccess, setModuleAccess] = useState<string[]>([]);
  const [progressCount, setProgressCount] = useState<Record<string, number>>(
    {},
  );

  const { t, i18n } = useTranslation("general");

  useEffectOnce(() => {
    getModules().then((result) => {
      setModules(result);
    });

    getModuleAccess().then((result) => {
      setModuleAccess(result);
    });

    getLessons().then((result) => {
      setLessons(result);
    });

    getModuleCount().then((result) => {
      setProgressCount(result);
    });
  });

  return (
    <div className="allbanner">
      <div
        className="banner mySlides fade flex justify-evenly wrap"
        id="banner2"
        style={{
          backgroundColor: `${skills ? "#FEBB26" : "#184053"}`,
          minHeight: "400px",
        }}
      >
        <div className="banner-title w30" style={{ textAlign: "center" }}>
          <h1 style={{ color: "white" }}>
            {skills ? t("skills") : t("modules")}
          </h1>
        </div>
        <img
          src={skills ? SkillsImg : ModulesImg}
          style={{
            maxHeight: "350px",
            maxWidth: skills ? "303px" : "470px",
            width: "auto",
          }}
        />
      </div>
      <div className="modules mt-4">
        <div>
          <div className="flex wrap">
            {modules
              .sort((a, b) => a.order - b.order)
              .filter((m) => m.skills === skills)
              .filter((m) => {
                return (
                  (i18n.language === "fr" && m.titleFR.trim() !== "") ||
                  (i18n.language === "en" && m.titleEN.trim() !== "") ||
                  i18n.language === "nl"
                );
              })
              .map((module) => {
                return (
                  <ModuleView
                    available={
                      moduleAccess.includes(module.id) || module.price === 0
                    }
                    lessons={lessons.filter(
                      (lesson) => lesson.moduleId === module.id,
                    )}
                    module={module}
                    progressCount={progressCount[module.id] || 0}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
