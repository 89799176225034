import React, { useRef, useState, Fragment } from "react";
import { getModules, Module } from "./api/ModulesApi";
import { requestDemo } from "./api/BusinessApi";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import { Slider, SliderDuration, Sliders } from "./Slider";
import { Footer } from "./Footer";
import "./Landingpage.css";
import { getLogos, Logo } from "./api/LogosApi";
import HeroPicture from "./img/landing-hero.jpg";
import LandingPic1 from "./img/landing-pic1.png";
import { getPartners, Partner } from "./api/PartnersApi";
import USP1 from "./img/usp1.png";
import USP2 from "./img/usp2.png";
import USP3 from "./img/usp3.png";
import USP4 from "./img/usp4.png";
import WavesTop from "./img/WavesTop.svg";
import WavesBottom from "./img/WavesBottom.svg";
import BloomUp from "./img/Logo - no tagline.svg";
import Dashboard from "./img/desktop.png";
import Check from "./img/check.svg";
import { getTestimonials, Testimonial } from "./api/TestimonialsApi";
import { ImpactSlider } from "./ImpactSlider";
import { Navigate } from "react-router-dom";
import YellowWaves from "./img/waves.png";
import { getCmsItem } from "./api/CMSApi";
import { getCorrectUrl, isB2C } from "./Utils";

export function Landingpage({ authenticated }: { authenticated: boolean }) {
  const [modules, setModules] = useState<Module[]>([]);
  const [logos, setLogos] = useState<Logo[]>([]);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const { i18n, t } = useTranslation("landing");
  const videoRef = useRef<any>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [business, setBusiness] = useState("");
  const [message, setMessage] = useState("");
  const [data, setData] = useState<any>({});
  const amountOfUsers = 17603;

  useEffectOnce(() => {
    getModules().then((result) => {
      setModules(result);
    });

    getCmsItem("landingpage").then((result) => {
      try {
        const parsed = JSON.parse(result.data);

        setData(parsed);
      } catch {
        // Do nothing
      }
    });

    getLogos().then((result) => {
      setLogos(result);
    });
    getPartners().then((result) => {
      setPartners(result);
    });
    getTestimonials().then((result) => {
      setTestimonials(result);
    });
  });

  if (authenticated) {
    return <Navigate replace to="/home" />;
  }

  const mentalDiv = (
    <div className="block mental">
      <div className="content">
        <div className="title">{isB2C() ? t("mental-b2c") : t("mental")}</div>
        <div className="usps">
          <div className="usp">
            <img alt="usp" src={USP1} />
            <div className="mb-auto">{t("usp1")}</div>
          </div>
          <div className="usp">
            <img alt="usp" src={USP2} />
            <div className="mb-auto">{t(isB2C() ? "usp2-b2c" : "usp2")}</div>
          </div>
          <div className="usp">
            <img alt="usp" src={USP3} />
            <div className="mb-auto">{t(isB2C() ? "usp3-b2c" : "usp3")}</div>
          </div>
          <div className="usp">
            <img alt="usp" src={USP4} />
            <div className="mb-auto">{t("usp4")}</div>
          </div>
        </div>

        {isB2C() ? (
          <div className="button yellow w-fit">
            <a className="no-decoration" href={"/modules"}>
              {t("requestDemo-b2c")}
            </a>
          </div>
        ) : (
          <div className="buttonContainer">
            <button
              className="button yellow w-fit h-center mx-auto"
              onClick={() => {
                window.open(
                  `${process.env.NX_PUBLIC_BLOOMUP_WEBSITE}/${i18n.language}/demo`,
                );
              }}
            >
              {t("requestDemo")}
            </button>
            <button
              className="button outlined w-fit h-center mx-auto"
              onClick={() => {
                window.open(
                  `${process.env.NX_PUBLIC_BLOOMUP_WEBSITE}/${i18n.language}/platform`,
                );
              }}
            >
              {t("platform")}
            </button>
          </div>
        )}
      </div>
    </div>
  );

  const testimonialsDiv = (
    <div className="block testimonials">
      <div className="content">
        <div className="title">{t("testimonials")}</div>
        <Slider
          automatic={false}
          givenData={testimonials.map((item) => {
            return {
              name: item.name,
              score: item.score,
              text: item.text,
              type: Sliders.TESTIMONIAL,
            };
          })}
          key={1}
          type={Sliders.TESTIMONIAL}
        />
      </div>
    </div>
  );

  const partnersDiv = (
    <div className="block w-100">
      <div className="content">
        <div className="title">{t("partners")}</div>
        <Slider
          automatic={true}
          givenData={partners.map((item) => {
            return {
              image: getCorrectUrl(item.url),
              type: Sliders.IMAGE,
            };
          })}
          key={2}
          type={Sliders.IMAGE}
        />
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className="landingpage">
        <div className="hero-wrapper">
          <div className="content hero">
            <div className="left">
              <div className="title">{t("stepByStep")}</div>
              <button
                className="button yellow w-fit"
                onClick={() => {
                  if (!videoRef) {
                    return;
                  }

                  if (!videoRef.current) {
                    return;
                  }

                  videoRef.current?.scrollIntoView();
                }}
              >
                {t("offer")}
              </button>
              {isB2C() ? null : (
                <button
                  className="button subtitle pink w-fit no-decoration black mt-4"
                  onClick={() => {
                    window.open(
                      `${process.env.NX_PUBLIC_BLOOMUP_WEBSITE}/${i18n.language}/demo`,
                    );
                  }}
                >
                  {t("orRequestDemo")}
                </button>
              )}
            </div>
            <img alt={"stap voor stap"} className="right" src={HeroPicture} />
          </div>
        </div>
        <div className="block programs">
          <div className="content">
            <div className="title">{t("programs")}</div>
            <Slider
              automatic={true}
              givenData={modules
                .filter((item) => {
                  return (
                    (i18n.language === "nl" && item.titleNL) ||
                    (i18n.language === "en" && item.titleEN) ||
                    (i18n.language === "fr" && item.titleFR)
                  );
                })
                .map((item) => {
                  return {
                    image: getCorrectUrl(item.image),
                    link: `/modules/${item.id}`,
                    programName:
                      i18n.language === "nl"
                        ? item.titleNL
                        : i18n.language === "en"
                        ? item.titleEN
                        : item.titleFR,
                    type: Sliders.IMAGE,
                  };
                })}
              key={3}
              showArrows={true}
              type={Sliders.IMAGE}
            />
          </div>
        </div>
        {isB2C() ? null : (
          <div className="block">
            <div className="content">
              <div className="title">{t("100+")}</div>
              <div className="subTitle">{t("100Sub")}</div>
              <Slider
                automatic={true}
                duration={SliderDuration.LOGO}
                givenData={logos.map((item) => {
                  return {
                    image: getCorrectUrl(item.url),
                    type: Sliders.IMAGE,
                  };
                })}
                key={4}
                type={Sliders.IMAGE}
              />
            </div>
          </div>
        )}
        <div className="block information-wrapper">
          <div className="content information">
            <img alt="pic1" className="left" src={LandingPic1} />
            <div className="right">
              <div className="title">
                {(i18n.language === "nl"
                  ? data.title2NL
                  : i18n.language === "en"
                  ? data.title2EN
                  : data.title2FR) ||
                  t(isB2C() ? "prevention-b2c" : "prevention")}
              </div>
              <div className="text">
                {(i18n.language === "nl"
                  ? data.text2NL
                  : i18n.language === "en"
                  ? data.text2EN
                  : data.text2FR) ||
                  t(
                    isB2C()
                      ? "preventionExplenation-b2c"
                      : "preventionExplenation",
                  )}
              </div>
            </div>
          </div>
          {isB2C() ? mentalDiv : null}
          {isB2C() ? testimonialsDiv : null}
          {isB2C() ? null : (
            <div className="block waves">
              <div className="content">
                <div className="title">{t("numbers")}</div>
                <div className="stats-wrapper">
                  <div>
                    <div className="number">{`+${amountOfUsers}`}</div>
                    <div className="text">{t("users")}</div>
                  </div>
                  <div>
                    <div className="number">85%</div>
                    <div className="text">{t("loyality")}</div>
                  </div>
                  <div>
                    <div className="number">8.9/10</div>
                    <div className="text">{t("happyness")}</div>
                  </div>
                  <div>
                    <div className="number">+15</div>
                    <div className="text">{t("programs")}</div>
                  </div>
                </div>
              </div>
              <img alt={"waves"} className="wave-img" src={YellowWaves} />
            </div>
          )}
          {isB2C() && i18n.language !== "nl" ? null : (
            <div className="block">
              <div className="content video" ref={videoRef}>
                <div className="title">
                  {isB2C() ? t("partner-b2c") : t("partner")}
                </div>
                <div className="subtitle">
                  {isB2C() ? t("invest-b2c") : t("invest")}
                </div>
                {isB2C() ? (
                  <iframe
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    frameBorder="0"
                    height="615"
                    src="https://www.youtube.com/embed/bTOSz97bb8E?si=X645i8S9WnoonNas"
                    title="YouTube video player"
                    width="960"
                  ></iframe>
                ) : (
                  <>
                    <iframe
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                      className="video-iframe"
                      frameBorder="0"
                      src={
                        i18n.language === "nl"
                          ? `https://player.vimeo.com/video/827572967?h=fc084fac92&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
                          : i18n.language === "en"
                          ? `https://player.vimeo.com/video/833637449?h=ebd4edcff0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
                          : `https://player.vimeo.com/video/837616617?h=cf785ecfc6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
                      }
                      title="MINDLAB SHORT"
                    ></iframe>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                  </>
                )}
              </div>
            </div>
          )}
          {isB2C() ? null : mentalDiv}
          {isB2C() ? null : (
            <div className="block waves-block upside">
              <img alt={"waves"} src={WavesTop} />
            </div>
          )}
          {isB2C() ? partnersDiv : null}
          <div className="block history-wrapper">
            <div className="content history">
              <div className="left">
                <div className="title">
                  {(i18n.language === "nl"
                    ? data.title1NL
                    : i18n.language === "en"
                    ? data.title1EN
                    : data.title1FR) || t("story")}
                </div>
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html:
                      (
                        (i18n.language === "nl"
                          ? data.text1NL
                          : i18n.language === "en"
                          ? data.text1EN
                          : data.text1FR) || ""
                      ).replace(
                        "{users}",
                        ` <span class="yellow"><b>${amountOfUsers}</b></span> `,
                      ) ||
                      `${t("story1")}<br /><br />${t(
                        "story2",
                      )} <span class="yellow"><b>${t("story3", {
                        numberOfUsers: `${amountOfUsers}`,
                      })}</b></span> \n${t("story4")}`,
                  }}
                />
              </div>
              <div className="right">
                <img className="right-img" src={BloomUp} />
              </div>
            </div>
          </div>
          {isB2C() ? null : (
            <>
              <div className="block waves-block upside-down">
                <img alt={"waves"} src={WavesBottom} />
              </div>
              <div className="block dashboard-wrapper">
                <div className="content">
                  <div className="title">{t("insights")}</div>
                  <div className="dashboard">
                    <img className="left" src={Dashboard} />
                    <div className="right mt-auto mb-auto">
                      <div className="subtitle">{t("insight1")}</div>
                      <div className="rois">
                        <div className="roi">
                          <img alt="check" src={Check} />
                          <div>{t("insight2")}</div>
                        </div>
                        <div className="roi">
                          <img alt="check" src={Check} />
                          <div>{t("insight3")}</div>
                        </div>
                        <div className="roi">
                          <img alt="check" src={Check} />
                          <div>{t("insight4")}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {isB2C() ? null : testimonialsDiv}
          {isB2C() ? null : (
            <div className="block impact-wrapper">
              <div className="content impact">
                <div className="title">{t("why")}</div>
                <ImpactSlider />
              </div>
            </div>
          )}
          {isB2C() ? null : (
            <div className="block">
              <div className="content">
                <div className="title">{t("discover")}</div>
                <form className="form">
                  <div className="subtitle">{t("ask")}</div>
                  <div className="input-row mb-4">
                    <div className="input-wrapper">
                      <label>{t("name")}:</label>
                      <input
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        required={true}
                        type="text"
                        value={name}
                      />
                    </div>
                    <div className="input-wrapper">
                      <label>{t("business")}:</label>
                      <input
                        onChange={(e) => {
                          setBusiness(e.target.value);
                        }}
                        required={true}
                        type="text"
                        value={business}
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-wrapper">
                      <label>{t("email")}:</label>
                      <input
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required={true}
                        type="email"
                        value={email}
                      />
                    </div>
                    <div>
                      <button
                        className="button yellow"
                        onClick={async () => {
                          await requestDemo(email, name, business);
                          setMessage("Aanvraag verzonden!");
                          setEmail("");
                          setName("");
                          setBusiness("");
                        }}
                        type="submit"
                      >
                        {t("send")}
                      </button>
                    </div>
                  </div>
                  <div>{message}</div>
                </form>
                <div className="flex">
                  <a
                    className="button mb-4 ml-auto mr-auto mt-4"
                    href={
                      i18n.language === "nl"
                        ? "/Introducing_BloomUp_NL.pdf"
                        : i18n.language === "en"
                        ? "/Introducing_BloomUp_EN.pdf"
                        : "/Introducing_BloomUp_FR.pdf"
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t("download")}
                  </a>
                </div>
              </div>
            </div>
          )}
          {isB2C() ? null : partnersDiv}
        </div>
      </div>
      <Footer authenticated={authenticated} />
    </Fragment>
  );
}
