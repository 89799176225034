import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "./FaceScannerModal.css";
import { API_ENDPOINT, getHeaders } from "./api/AuthApi";
import { FaceScanResults } from "./FaceScanResults";
import { FaceScanResultType } from "./Types";
import { IntelliProveResults } from "./api/IntelliProveApi";
import { useTranslation } from "react-i18next";

export function FaceScannerModal({
  authenticated,
  isOpen,
  onRequestClose,
}: {
  authenticated: boolean;
  isOpen: boolean;
  onRequestClose: () => void;
}) {
  const { t } = useTranslation();
  const [pluginUrl, setPluginUrl] = useState<string>();
  const [results, setResults] = useState<IntelliProveResults>();
  const [errorMsg, setErrorMsg] = useState<string>();

  useEffect(() => {
    if (!pluginUrl && !errorMsg && authenticated) {
      (async () => {
        const response = await fetch(
          `${API_ENDPOINT}/intelliprove/plugin-url`,
          {
            headers: getHeaders(),
            method: "GET",
          },
        );

        if (response.ok) {
          const data = await response.json();

          setPluginUrl(data.url);
        } else {
          setErrorMsg(t("face-scanner:pluginNotLoaded"));
        }
      })();
    }
  }, [authenticated, errorMsg, pluginUrl, setPluginUrl, t]);

  useEffect(() => {
    const handler = async (event: MessageEvent) => {
      if (event.data?.stage === "results") {
        const response = await fetch(
          `${API_ENDPOINT}/intelliprove/results/${event.data.uuid}`,
          {
            headers: getHeaders(),
            method: "GET",
          },
        );

        const results = await response.json();

        if (results.errorCode === 0) {
          setResults(results);
        } else {
          setErrorMsg(t("face-scanner:gettingResultsFailed"));
        }
      }
    };

    window.addEventListener("message", handler);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handler);
  }, [t]);

  return (
    <ReactModal
      appElement={window.document.getElementById("root") as HTMLElement}
      bodyOpenClassName="scanner-open"
      className="scanner-body"
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose();
        setPluginUrl(undefined);
        setResults(undefined);
        setErrorMsg(undefined);
      }}
      overlayClassName="scanner-overlay"
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
    >
      {errorMsg ? <div className="scanner-error">{errorMsg}</div> : null}

      {results ? (
        <div style={{ height: "100%", overflowX: "auto" }}>
          <FaceScanResults
            itemsToShow={[
              FaceScanResultType.HEART_RATE,
              FaceScanResultType.RESPIRATORY_RATE,
              FaceScanResultType.HEART_RATE_VARIABILITY,
              FaceScanResultType.RESONANT_BREATHING_SCORE,
            ]}
            results={results}
          />
        </div>
      ) : (
        <iframe
          allow="camera"
          height={"100%"}
          seamless={true}
          src={pluginUrl}
          style={{ border: 0, borderRadius: "15px" }}
          title="Facescanner"
          width={"100%"}
        />
      )}
    </ReactModal>
  );
}
