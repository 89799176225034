import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { UserDashboardSidebar } from "./UserDashboardSidebar";
import {
  getUserTestScores,
  listTests,
  Test,
  UserTestScore,
} from "./api/TestApi";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { RowData, Table } from "./Table";
import "./UserDashboardReports.css";
import { TestReport } from "./TestReport";
import { DateTime } from "luxon";
import { IntelliProveApi, IntelliProveResults } from "./api/IntelliProveApi";
import getResultsForUser = IntelliProveApi.getResultsForUser;
import { FaceScanReport } from "./FaceScanReport";
import { FaceScanResultType } from "./Types";
import {
  createFaceScanResultImage,
  createTextImage,
} from "./QuarterlyReport/getReportImage";
import { detailsTable, getCorrectResult } from "./FaceScanResults";
import { toast, ToastContainer } from "react-toastify";
import { pdf } from "@react-pdf/renderer";

enum ReportType {
  SCAN = "scan",
  TEST = "test",
}

export function UserDashboardReports() {
  const [tests, setTests] = useState<Test[]>([]);
  const [faceScanResults, setFaceScanResults] = useState<IntelliProveResults[]>(
    [],
  );

  const [scores, setScores] = useState<UserTestScore[]>();
  const [rowData, setRowData] =
    useState<RowData<string, string, string | string[], ReportType>>();

  const { t, i18n } = useTranslation("user-dashboard");
  const { t: tScan } = useTranslation("scan");
  const translationResponse = useTranslation("general");
  const translationResponseScan = useTranslation("scan");

  useEffectOnce(() => {
    listTests().then((tests) => {
      setTests(tests);
    });

    getUserTestScores().then((scores) => {
      // sort scores by date descending
      scores.sort((score1, score2) => -score1.date.localeCompare(score2.date));
      setScores(scores);
    });

    getResultsForUser().then((results) => {
      setFaceScanResults(results);
    });
  });

  useEffect(() => {
    if (!tests) {
      return;
    }

    if (!scores) {
      return;
    }

    if (!faceScanResults) {
      return;
    }

    const rowData: RowData<string, string, string | string[], ReportType> = [];

    const uniqueDates = Array.from(new Set(scores.map((t) => t.date)));

    for (const date of uniqueDates) {
      const foundScores = scores.filter((s) => s.date === date);

      if (foundScores.length === 0) {
        continue;
      }

      const test = tests.find((test) => test.id === foundScores[0].testId);

      if (!test) {
        continue;
      }

      rowData.push({
        buttonColValue: foundScores.map((s) => s.id),
        col1Value: test.title_nl,
        col2Value: date,
        type: ReportType.TEST,
      });
    }

    for (const scan of faceScanResults) {
      rowData.push({
        buttonColValue: scan.id,
        col1Value: "Face scan",
        col2Value: scan.timestamp,
        type: ReportType.SCAN,
      });
    }

    setRowData(rowData);
  }, [tests, scores, faceScanResults]);

  function downloadTestReport(v: string | string[]) {
    if (!Array.isArray(v)) {
      return;
    }

    if (!scores) {
      return;
    }

    if (!tests) {
      return;
    }

    const mappedScores = v
      .map((s) => scores.find((score) => score.id === s))
      .filter((s) => s !== undefined);

    if (mappedScores.length === 0) {
      return;
    }

    if (mappedScores[0] === undefined) {
      return;
    }

    const test = tests.find((t) => t.id === mappedScores[0]?.testId);

    if (!test) {
      return;
    }

    const toastId = toast.loading("Creating report...");

    setTimeout(async () => {
      const mappedResults = mappedScores.map((item) => {
        if (item === undefined) {
          return "";
        }

        const groupScore = test.testScores
          .filter((s) => s.groupId === item.groupId && s.ltScore > item.score)
          .sort((a, b) => a.ltScore - b.ltScore)[0];

        if (!groupScore) {
          return "";
        }

        return i18n.language === "nl"
          ? groupScore.explanation_nl
          : i18n.language === "en"
          ? groupScore.explanation_en || ""
          : groupScore.explanation_fr || "";
      });

      const imageResults = [];

      for (const item of mappedResults) {
        imageResults.push(await createTextImage(item));
      }

      const blob = await pdf(
        <TestReport
          date={DateTime.fromISO(mappedScores[0]?.date || "").toFormat(
            "dd/LL/y",
          )}
          results={imageResults}
          title={
            i18n.language === "nl"
              ? test.title_nl
              : i18n.language === "en"
              ? test.title_en || ""
              : test.title_fr || ""
          }
          translationResponse={translationResponse}
        />,
      ).toBlob();

      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);
      link.download = `${
        i18n.language === "nl"
          ? test.title_nl
          : i18n.language === "en"
          ? test.title_en || ""
          : test.title_fr || ""
      } ${DateTime.fromISO(mappedScores[0]?.date || "").toFormat(
        "dd/LL/y",
      )}.pdf`;
      link.click();

      toast.dismiss(toastId);
    }, 1);
  }

  function downloadFaceScanReport(v: string | string[]) {
    if (!faceScanResults) {
      return;
    }

    const foundResult = faceScanResults.find((f) => f.id === v);

    if (!foundResult) {
      return;
    }

    const toastId = toast.loading("Creating report...");

    setTimeout(async () => {
      const results = await Promise.all(
        [
          FaceScanResultType.HEART_RATE,
          FaceScanResultType.RESPIRATORY_RATE,
          FaceScanResultType.HEART_RATE_VARIABILITY,
          FaceScanResultType.RESONANT_BREATHING_SCORE,
        ].map(async (item) => {
          const image = await createFaceScanResultImage({
            adviceText: tScan(
              `${detailsTable[item].name}.${detailsTable[item].group(
                getCorrectResult(foundResult, item),
              )}.right`,
            ),
            caretIcon: null,
            color: tScan(
              `${detailsTable[item].name}.${detailsTable[item].group(
                getCorrectResult(foundResult, item),
              )}.color`,
            ),
            gradient: detailsTable[item].gradient,
            iconSrc: detailsTable[item].icon,
            onClick: () => {
              //Nothing
            },
            open: false,
            resultText: tScan(
              `${detailsTable[item].name}.${detailsTable[item].group(
                getCorrectResult(foundResult, item),
              )}.left`,
            ),
            textScore: detailsTable[item].textScore(
              getCorrectResult(foundResult, item),
            ),
            title: tScan(`${detailsTable[item].name}.title`),
            value: getCorrectResult(foundResult, item),
            valueMax: detailsTable[item].max,
            valueMin: detailsTable[item].min,
          });

          return {
            advice: tScan(
              `${detailsTable[item].name}.${detailsTable[item].group(
                getCorrectResult(foundResult, item),
              )}.right`,
            ),
            image: image,
            result: tScan(
              `${detailsTable[item].name}.${detailsTable[item].group(
                getCorrectResult(foundResult, item),
              )}.left`,
            ),
          };
        }),
      );

      const blob = await pdf(
        <FaceScanReport
          date={DateTime.fromISO(foundResult.timestamp).toFormat("dd/LL/y")}
          results={results}
          title={"Face scan"}
          translationResponse={translationResponseScan}
        />,
      ).toBlob();

      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);
      link.download = `Face scan ${DateTime.fromISO(
        foundResult.timestamp,
      ).toFormat("dd/LL/y")}.pdf`;
      link.click();

      toast.dismiss(toastId);
    }, 1);
  }

  return (
    <UserDashboardSidebar>
      <div className="dashboard-reports">
        <h2 className="title-underlined">{t("reports.title")}</h2>
        {rowData ? (
          <Table<string, string, string | string[], ReportType>
            buttonIcon={faDownload}
            col1Data={{
              headerName: t("reports.testTitle"),
            }}
            col2Data={{
              cellRenderer: ({ value }: { value: string }) =>
                new Date(value).toLocaleDateString(i18n.language, {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                }),
              headerName: t("reports.date"),
            }}
            onButtonClick={(v, type) => {
              console.log(v, type);

              if (type === ReportType.TEST) {
                downloadTestReport(v);

                return;
              }

              if (type === ReportType.SCAN) {
                downloadFaceScanReport(v);

                return;
              }
            }}
            rowData={rowData}
          />
        ) : null}
      </div>
      <ToastContainer />
    </UserDashboardSidebar>
  );
}
