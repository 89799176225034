import React, { Fragment, useEffect, useState } from "react";
import "./TestView.css";
import {
  getTest,
  listTests,
  listTestTypes,
  Test,
  TestType,
} from "./api/TestApi";
import { useEffectOnce } from "react-use";
import { Link, useParams } from "react-router-dom";
import { LoadTest } from "./Tests/LoadTest";
import { loggedIn } from "./api/AuthApi";
import { useTranslation } from "react-i18next";
import TestsImg from "./img/headers/Tests.svg";
import { isMobile } from "react-device-detect";
import { getCorrectUrl } from "./Utils";
import { Footer } from "./Footer";

export function TestsView({ authenticated }: { authenticated: boolean }) {
  const { typeId, testId } = useParams<{ testId: string; typeId: string }>();
  const [testTypes, setTestTypes] = useState<TestType[]>([]);
  const [tests, setTests] = useState<Test[]>([]);
  const [test, setTest] = useState<Test>();

  const { t, i18n } = useTranslation("general");

  const testsByTypeId = typeId
    ? tests.filter((t) => t.testType === typeId)
    : [];

  useEffectOnce(() => {
    listTestTypes().then((result) => {
      setTestTypes(result);
    });

    listTests().then((result) => {
      setTests(result);
    });

    if (testId) {
      getTest(testId).then((result) => {
        setTest(result);
      });
    }
  });

  useEffect(() => {
    if (testId) {
      getTest(testId).then((result) => {
        setTest(result);
      });
    }
  }, [testId]);

  return (
    <Fragment>
      <div className="testview">
        <div
          className="banner mySlides fade flex justify-evenly wrap"
          id="banner2"
          style={{ backgroundColor: "#719FAC", minHeight: "400px" }}
        >
          <div className="banner-title w30" style={{ textAlign: "center" }}>
            <h1 style={{ color: "white" }}>{t("banner3-title")}</h1>
          </div>
          {isMobile ? null : (
            <img
              src={TestsImg}
              style={{ maxHeight: "350px", maxWidth: "350px" }}
            />
          )}
        </div>
        {!loggedIn() ? (
          <div>{t("login-first")}</div>
        ) : (
          <div className="flex">
            {isMobile && (typeId || testId || test) ? null : (
              <div className={`sidebar ${isMobile ? "w-100" : ""}`}>
                <h4 className="type">{t("overview")}</h4>
                {testTypes.map((type) => {
                  const linkedTests = tests.filter(
                    (t) => t.testType === type.id,
                  );

                  return (
                    <div className="type">
                      <Link
                        className="flex items-center decoration-none"
                        to={`/tests/${type.id}`}
                      >
                        <div className="circle-closed" />
                        {i18n.language === "nl" && type.titleNL}
                        {i18n.language === "en" && type.titleEN}
                        {i18n.language === "fr" && type.titleFR}
                      </Link>
                      <div className="test">
                        {linkedTests.map((test) => {
                          return (
                            <Link
                              className="flex items-center decoration-none"
                              to={`/tests/${type.id}/test/${test.id}`}
                            >
                              <div className="circle-open" />
                              {i18n.language === "nl" && test.title_nl}
                              {i18n.language === "en" && test.title_en}
                              {i18n.language === "fr" && test.title_fr}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              className="w-100"
              style={{ display: isMobile && !typeId && !testId ? "none" : "" }}
            >
              {!isMobile && !typeId && !testId ? (
                <div className="flex justify-center wrap test-align">
                  {testTypes.map((type) => {
                    return (
                      <Link
                        style={{ color: "#4D5C6D", textDecoration: "none" }}
                        to={`/tests/${type.id}`}
                      >
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}
                        >
                          <img
                            src={getCorrectUrl(type.image)}
                            style={{
                              margin: "40px",
                              marginBottom: "auto",
                              width: "200px",
                            }}
                          />
                          <div
                            style={{
                              marginTop: "auto",
                              textTransform: "uppercase",
                            }}
                          >
                            {i18n.language === "nl" && type.titleNL}
                            {i18n.language === "en" && type.titleEN}
                            {i18n.language === "fr" && type.titleFR}
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              ) : null}
              {typeId && !testId ? (
                <div>
                  {testsByTypeId.map((test) => {
                    return (
                      <div className="flex flex-column" key={test.id}>
                        <div className="test-info">
                          <div className="test-title">
                            {i18n.language === "nl" && test.title_nl}
                            {i18n.language === "en" && test.title_en}
                            {i18n.language === "fr" && test.title_fr}
                          </div>
                          <div
                            className="test-description"
                            dangerouslySetInnerHTML={{
                              __html:
                                i18n.language === "nl"
                                  ? test.description_nl
                                  : i18n.language === "en"
                                  ? test.description_en || ""
                                  : test.description_fr || "",
                            }}
                          />
                        </div>
                        <Link
                          className="test-button"
                          to={`/tests/${typeId}/test/${test.id}?started=true`}
                        >
                          {t("start_test")}
                        </Link>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {typeId && testId && test ? (
                <div className="ml-auto mr-auto">
                  <LoadTest test={test} />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      <Footer authenticated={authenticated} />
    </Fragment>
  );
}
